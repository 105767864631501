.InvestmentROIContainer {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}

.InvestmentROIContainer .formControl {
    min-width: 20%;
    margin-right: 15px;
    margin-bottom: 15px;
}

.InvestmentROIContainer .contactUsBtn i {
    font-size: 26px;
}

.InvestmentROIContainer .MuiInputLabel-outlined{
   transform: translate(0px, 24px) scale(1);
}

.InvestmentROIContainer .MuiInputLabel-shrink {
    transform: translate(0px, 1.5px) scale(0.75);
}


/* .MuiInputLabel-formControl {
    transform: translate(14px, 24px) scale(1) !important;
} */


/* .InvestmentROIContainer .MuiInputLabel-formControl {
    top: 0;
    left: 14px;
    position: absolute;
    transform: translate(0px, 24px) scale(1);
} */
.InvestmentROIContainer .container-title{
    margin-bottom: 35px !important;
}
.InvestmentROIContainer .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(0px, 2px) scale(0.75) !important;
}
.InvestmentROIContainer{
    padding-bottom: 45px !important;
}
.InvestmentROIContainer .MuiInputLabel-animated{
    padding-left: 18px;
}
.InvestmentROIContainer .MuiOutlinedInput-input {
    padding: 21px 14px;
}
.InvestmentROIContainer .MuiSelect-select{
    color:  #1a1a1a;
}
.InvestmentROIContainer label{
width:300px;
}
.modalCnt {
    width: 75%;
    margin: auto;
    margin-top: 5%;
    padding: 25px;
    outline: none;
    background: #FFF;
    border-radius: 5px;
    position: relative;
}

@media screen and (min-width:1024px){
.modalCnt{
    height: 90vh;
    overflow-y: auto;
}

}

.closeBtn {
    /* font-size: 35px; */
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
}

.colorRed {
    color: #c62828 !important;
}

.margin0 {
    margin: 0 !important;
}

.modalCnt h2 {
    margin-bottom: 0;
}
.investorForm{
    width: 100%;
    padding: 0 0px;
    display: inline;
    flex-wrap: wrap;
    justify-content: center;
}
.modalCnt .returnsDetails {
    text-align: right;
    margin: 0px !important;
    margin-bottom: 5px !important;
}
.MuiFormControl-marginNormal{
    margin: 0 !important;
}
@media screen and (max-width: 639px) {
    .MuiFormControl-marginNormal{
        margin: 8px !important;        
    }
    .InvestmentROIContainer .formControl {
        min-width: 245px;
    }
  }
  
  
  @media screen and (max-width: 1024px) {
    .InvestmentROIContainer .formControl {
        min-width: 48%;
        margin-bottom: 20px;
        margin-right: 0;

    }
    .investorForm{
        display: flex;
        justify-content: space-between;

    }
    .investorForm .MuiButton-containedPrimary{
        width: 40%;
    }
  
  }

   
  @media screen and (max-width: 769px) {
    .InvestmentROIContainer .formControl {
        min-width: 100%;
        margin-bottom: 15px;
        margin-right: 0;

    }
    .investorForm{
        display: flex;
        justify-content: space-between;

    }
    .investorForm .MuiButton-containedPrimary{
        width: 100%;
    }
  
  }