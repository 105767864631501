.forManufactures{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url("../../images/natural-honey-various-jars-wooden-shelf-souvenir-village-market.jpg");
}

.for-manufacturers-wrapper{
    margin:22px 0 70px 0;
}
.Iaa-Cards-mod{
    box-shadow: none;
    border: none;
}
.Iaa-Cards-mod img{
    width: 100px !important;
    height: 100px !important;
}

.Iaa-manufacturer-tabs .container-para + div > div:first-child{
    width: 40%;
}
.card-activated{
    border: 1px solid #2A4355;
    cursor: initial !important;
}
.card-activated:checked{
    border: none !important;
    cursor: initial !important;
}